

import Experience from "../Experience";
import Router from "../../router/router";


export default class Panier {
    constructor() {
        this.experience = new Experience()
        this.isOpen = false
        this.panierSize = document.querySelector('.panier-size')
        this.panierButton = document.querySelector('.panier')
        this.panierContainer = document.querySelector('.panierContainer')
        this.panierOverflow = document.querySelector('.panierOverflow')
        this.closePanierButton = document.querySelector('.panierContainer .closeButton')
        this.panierDataSection = document.querySelector('.panierData')
        this.panierTotal = document.querySelector('.panierTotalValue')
        this.pay = document.querySelector('.submitPanier')
        this.data = []




        this.pay.addEventListener('click', (e)=>{
            this.closePanier()
            this.experience.router.route()
            this.experience.world.audio.clickSound.play()

        })

        this.panierDataSection.addEventListener('click', (event) => {

            const target = event.target;

            if (target.classList.contains('deleteButton')) {
            this.experience.world.audio.clickSound.play()

                const id = target.parentElement.querySelector('.id').innerHTML
                const container = target.parentElement.parentElement
                container.style.height = '0rem'


                setTimeout(() => {
                    this.experience.basket.removeAll({ "id": id })
                    // animer avant de update
                    this.update()
                }, 300)
            } else if (target.classList.contains('increment')) {
            this.experience.world.audio.clickSound.play()

                const id = target.parentElement.parentElement.querySelector('.id').innerHTML
                this.experience.basket.add({ "id": id })
                this.update()

            } else if (target.classList.contains('decrement')) {
            this.experience.world.audio.clickSound.play()

                const id = target.parentElement.parentElement.querySelector('.id').innerHTML
                this.experience.basket.remove({ "id": id })
                this.update()

            }

        });

        this.panierButton.addEventListener('click', e => {
            this.experience.world.audio.clickSound.play()


            if (!this.isOpen) {
                this.openPanier()

            } else {
                this.closePanier()

            }
        })
        this.closePanierButton.addEventListener('click', e => {
            this.experience.world.audio.clickSound.play()

            this.closePanier()
        })
        this.panierOverflow.addEventListener('click', e => {
            this.experience.world.audio.clickSound.play()

            this.closePanier()
        })



        this.update()


    }


    openPanier() {
        this.experience.world.destroyScrollEvent()
        this.panierContainer.style.transform = 'translateX(0)'
        this.panierOverflow.style.opacity = '1'
        this.panierOverflow.style.pointerEvents = 'auto'
        const lignes = document.querySelectorAll('.panierStroke')
        lignes.forEach((ligne, i) => {

            ligne.style.transition = 'none'
            ligne.style.width = '0%'

        })

        lignes.forEach((ligne, i) => {
            setTimeout(() => {

                ligne.style.transition = ' width 0.5s ease-in-out, opacity 0.3s ease-in-out'
                ligne.style.width = '100%'
            }, i * 150)

        })

        setTimeout(() => {
            document.querySelectorAll('.panierContainer .closeButton .cross').forEach((cross) => { cross.style.marginTop = '0rem' })




            this.isOpen = true

        }, 800)

    }




    closePanier() {
        this.experience.world.initScrollEvent()
        this.panierContainer.style.transform = 'translateX(100%)'
        this.panierOverflow.style.opacity = '0'
        this.panierOverflow.style.pointerEvents = 'none'
        const lignes = document.querySelectorAll('.panierStroke')


        setTimeout(() => {
            document.querySelectorAll('.panierContainer .closeButton .cross').forEach((cross) => { cross.style.marginTop = '30rem' })
            lignes.forEach((ligne, i) => {
                ligne.style.width = '0%'

            })
            this.isOpen = false


        }, 800)



    }

    update() {
        this.panierDataSection.innerHTML = ''
        this.panierSize.innerHTML = this.experience.basket.getNumberProduct()
        this.panierTotal.innerHTML = this.experience.basket.getTotalPrice() + '€'
        this.changeHtmlData()


    }

    changeHtmlData() {
        if (this.experience.basket.basket) {
            this.experience.basket.basket.forEach((product) => {
                this.panierDataSection.innerHTML += `
                <div class="panierItemContainer">
    
                <div class="panierDataContent">
                <div class="id">${product.id}</div>
              <div class="panierLeft">
                <img src="${product.pageImages[0]}" alt="" class="productImage">
                <div class="productInfos">
                  <p>${product.name}</p>
                  <p>Buste en Bronze</p>
                </div>
              </div>
              <div class="panierMid">
                <button class="decrement">-</button>
                <p>${product.quantity}</p>
                <button class="increment">+</button>
              </div>
              <div class="panierRight">${product.price}€</div>
            <div class="deleteButton"></div>
    
            </div>  
            <div class="panierStroke"></div>
            </div>  

            
                `
            })
        }
    }


}