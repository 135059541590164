import * as THREE from 'three'
import Experience from "../Experience";
import vertexShader from '../../shaders/floor/vertex.glsl'
import fragmentShader from '../../shaders/floor/fragment.glsl'







export default class Floor {

    constructor() {


        this.experience = new Experience()
        this.scene = this.experience.scene
        this.ressources = this.experience.ressources
        this.time = this.experience.time
        this.debugObject = {}




        this.setGeometry()
        this.setMaterial()
        this.setMesh()




    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(10, 10, 100, 100)
    }


    setMaterial() {
        this.debugObject.colorOne = '#f1f1f1'
        this.debugObject.colorTwo = '#020202'
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            wireframe: false,
            uniforms: {
                u_time: { value: this.experience.time.elapsed },
                u_colorOne: { value: new THREE.Color(this.debugObject.colorOne) },
                u_colorTwo: { value: new THREE.Color(this.debugObject.colorTwo) },
                u_scroll: { value: 0 }

            }
        })

        if (this.experience.debug.active) {
            const floor = this.experience.debug.ui.addFolder('floor')

            floor.addColor(this.debugObject, 'colorOne')
                .onChange((value) => {
                    this.material.uniforms.u_colorOne.value = new THREE.Color(this.debugObject.colorOne)
                })
            floor.addColor(this.debugObject, 'colorTwo')
                .onChange((value) => {
                    this.material.uniforms.u_colorTwo.value = new THREE.Color(this.debugObject.colorTwo)
                })







        }


    }
    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.rotation.x = - Math.PI * 0.5
        this.mesh.position.y = -2.5
        this.scene.add(this.mesh)


    }



    destroy() {

    }

    appear() {
        const targetValue = -0.6
        if (this.mesh.position.y <= targetValue) {
            this.mesh.position.y += 0.05 * (targetValue - this.mesh.position.y)
            requestAnimationFrame(this.appear.bind(this))
        } else {

            return
        }
    }




    update() {
        if (this.material.uniforms.u_scroll.value) {
            this.material.uniforms.u_time.value = this.experience.time.elapsed * 0.001
            this.material.uniforms.u_scroll.value = this.experience.world.currentScroll
        }
        this.material.uniforms.u_scroll.value += 1


    }


}