import * as THREE from 'three'
import Experience from "../Experience";






export default class AmbientSphere {

    constructor() {


        this.experience = new Experience()
        this.scene = this.experience.scene
        this.ressources = this.experience.ressources
        this.time = this.experience.time
        this.debugObject = {}


        this.setGeometry()
        this.setMaterial()
        this.setMesh()



    }

    setGeometry() {
        this.geometry = new THREE.SphereGeometry(5, 32, 32)
    }


    setMaterial() {
        this.debugObject.ambientColor = '#f5f5f5'

        this.material = new THREE.MeshBasicMaterial({ color: new THREE.Color(this.debugObject.color), wireframe: false, side: THREE.DoubleSide })

        if (this.experience.debug.active) {
            const ambientSphere = this.experience.debug.ui.addFolder('ambientSphere')

            ambientSphere.addColor(this.debugObject, 'ambientColor')
                .onChange((value) => {
                    this.material.color = new THREE.Color(this.debugObject.ambientColor)
                })
        }


    }
    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.visible = true
        this.scene.add(this.mesh)


    }







    update() {

    }


}