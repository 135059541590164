import * as THREE from 'three'
import Experience from "../../Experience";
import fragmentShader from '../../../shaders/loadingScreen/fragment.glsl'
import vertexShader from '../../../shaders/loadingScreen/vertex.glsl'
import Ripples from './Ripples'
import EventEmitter from "../../Utils/EventEmitter";
import Nav from '../Nav';






export default class LoadingScreen extends EventEmitter {

    constructor() {
        super()


        this.experience = new Experience()
        this.scene = this.experience.scene
        this.ressources = this.experience.ressources
        this.time = this.experience.time




        this.atelier = document.querySelector('.titleOne h1')
        this.missor = document.querySelector('.titleTwo h1')
        this.span = document.querySelectorAll('.main-title span')
        this.loaderText = document.querySelector('.loaderText')
        this.loaderBar = document.querySelector('.loaderBar')
        this.loaderTextItems = document.querySelectorAll('.loaderText div')
        this.enterButton = document.querySelector('.enterButton')
        this.cornerLeftV = document.querySelector('.corner-left .verticalStroke')
        this.cornerLeftH = document.querySelector('.corner-left .horizontalStroke')
        this.cornerRightV = document.querySelector('.corner-right .verticalStroke')
        this.cornerRightH = document.querySelector('.corner-right .horizontalStroke')
        this.destroyed = false;


        this.debugObject = {}

        this.pourcentage = 0
        setTimeout(() => {
            this.setGeometry()
            this.setTextures()
            this.setMaterial()
            this.materialAppear()
            this.setMesh()
            this.HtmlAppear()
            this.fakeLoadingAnnimation()
            this.ripples = new Ripples(this.screen, 1000, 1000, 0.1)

        }, 1000)

        this.enterButton.addEventListener('click', () => {




            this.ScreenDestroy()
            this.trigger('enterWebSite')
            this.experience.nav.appear()
        })





    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(4.5, 4.5)
    }

    setTextures() {
        this.brushTexture = this.ressources.items.brushTexture


    }
    setMaterial() {
        // this.debugObject.colorOne = '#ffdd00'
        // this.debugObject.colorTwo = '#ff9500'
        this.debugObject.colorOne = '#f1f1f1'
        this.debugObject.colorTwo = '#adadad'



        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            precision: 'lowp',
            wireframe: false,
            transparent: true,
            uniforms: {
                u_alpha: { value: 0 },
                u_time: { value: this.experience.time.elapsed },
                u_colorOne: { value: new THREE.Color(this.debugObject.colorOne) },
                u_colorTwo: { value: new THREE.Color(this.debugObject.colorTwo) },
                u_displacement: { value: null },
                u_noiseIntensity: { value: 1 },
                u_noiseFrequency: { value: 20 }
            }

        }

        )


        if (this.experience.debug.active) {
            const loadingScreen = this.experience.debug.ui.addFolder('loadingScreen')

            loadingScreen.addColor(this.debugObject, 'colorOne')
                .onChange((value) => {
                    this.material.uniforms.u_colorOne.value = new THREE.Color(this.debugObject.colorOne)
                })
            loadingScreen.addColor(this.debugObject, 'colorTwo')
                .onChange((value) => {
                    this.material.uniforms.u_colorTwo.value = new THREE.Color(this.debugObject.colorTwo)
                })
            loadingScreen.add(this.material.uniforms.u_noiseFrequency, 'value', 0, 100).name('noiseFrequency')
            loadingScreen.add(this.material.uniforms.u_noiseIntensity, 'value', 0, 50).name('noiseIntensity')







        }




    }

    materialAppear() {
        if (this.material.uniforms.u_alpha.value < 1) {
            this.material.uniforms.u_alpha.value += 0.01
            requestAnimationFrame(this.materialAppear.bind(this))
        } else {


            return
        }
    }
    setMesh() {
        this.screen = new THREE.Mesh(this.geometry, this.material)
        this.scene.add(this.screen)



    }






    // others

    HtmlAppear() {
        // apparition du texte
        setTimeout(() => {
            this.atelier.style.transform = `translateY(${0}%)`
        }, 100)
        this.missor.style.transform = `translateY(${0}%)`


        // apparition du cadre
        // vertical max = 338rem
        // horizontal max = 385rem

        setTimeout(() => {
            this.cornerRightH.style.width = '385rem'
            this.cornerLeftH.style.width = '385rem'


        }, 500)
        setTimeout(() => {
            this.cornerRightV.style.height = '338rem'
            this.cornerLeftV.style.height = '338rem'


        }, 300)


    }
    webglDisapear() {

        if (this.material.uniforms.u_alpha.value >= 0) {
            this.material.uniforms.u_alpha.value -= this.experience.time.delta;
            requestAnimationFrame(() => {
                this.webglDisapear();
            });
        } else {
            return;
        }
    }

    ScreenDestroy() {
        // disparition du texte
        this.atelier.style.transition = 'transform 0.5s ease-in-out'
        this.missor.style.transition = 'transform 0.5s ease-in-out'
        this.atelier.style.transform = `translateY(${-100}%)`
        this.missor.style.transform = `translateY(${100}%)`
        // disparition du cadre et du boutton
        this.cornerRightH.style.width = '0rem'
        this.cornerLeftH.style.width = '0rem'
        this.cornerRightV.style.height = '0rem'
        this.cornerLeftV.style.height = '0rem'
        this.loaderBar.style.width = `0rem`
        this.loaderBar.style.transition = `width .5s ease-in-out`
        this.enterButton.style.transform = `translateY(-105%)`


        this.webglDisapear()


        // timeout de destruction pour les performances
        setTimeout(() => {
            this.atelier.style.display = 'none'
            this.missor.style.display = 'none'
            this.span.forEach((span) => { span.style.display = 'none' })
            this.loaderText.style.display = 'none'
            this.loaderTextItems.forEach((item) => { item.style.display = 'none' })
            this.enterButton.style.display = 'none'
            this.cornerLeftV.style.display = 'none'
            this.cornerLeftH.style.display = 'none'
            this.cornerRightV.style.display = 'none'
            this.cornerRightH.style.display = 'none'

            // dispose du fond threejs
            this.material.dispose()
            this.geometry.dispose()
            this.scene.remove(this.screen)
            this.ripples.destroy()
            this.destroyed = true;
        }, 1500)

    }

    fakeLoadingAnnimation() {
        const ratio = this.ressources.progressRatio * 100

        if (ratio < 100) {

            this.loaderText.style.width = `${ratio / 100 * 1055}rem`
            this.loaderBar.style.width = `${ratio / 100 * 1055}rem`
            this.loaderTextItems.forEach((div) => {
                div.innerHTML = `${Math.floor(ratio)}`
                div.style.transform = `translateY(${0}%)`
            })
        } else {
            this.loaderText.style.width = `1055rem`
            this.loaderTextItems.forEach((div) => {
                div.innerHTML = `100`
                div.style.transform = `translateY(-100%)`
            })
            this.loaderBar.style.width = `1055rem`
            this.enterButton.style.transform = `translateY(0%)`



            return
        }


        requestAnimationFrame(() => {
            this.fakeLoadingAnnimation()
        })
    }


    update() {
        if (this.material) {
            this.material.uniforms.u_time.value = this.experience.time.elapsed * 0.001;

        }
        if (!this.destroyed && this.ripples) {
            this.ripples.update();
        }


    }


}