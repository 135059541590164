import Experience from "../Experience/Experience";
import EventEmitter from "../Experience/Utils/EventEmitter";
import Nav from "../Experience/World/Nav";

export default class Router extends EventEmitter {
    constructor() {
        super();
        this.experience = new Experience();
        this.camera = this.experience.camera;
        this.canvas = this.experience.canvas;
        this.html = null;
        this.path = null;
        this.prevRoute = window.location.pathname;
        this.isRouting = false;

        this.routes = {
            404: "./pages/404.html",
            "/": "./index.html",
            "/about": "./pages/about.html",
            "/contact": "./pages/contact.html",
        };

        fetch('./productData.json')
            .then(response => response.json())
            .then(data => {
                for (let i = 0; i < data.data.length; i++) {
                    const product = data.data[i];
                    this.routes[`/${product.textureName}`] = `./pages/page.html`;
                }
                this.handleLocation(); // Appel de handleLocation une fois toutes les routes ajoutées
            })
            .catch(error => {
                console.error('Une erreur s\'est produite lors de la récupération des données du produit :', error);
            });

        window.onpopstate = this.handleLocation;
    }

    route = (event) => {
        event = event || window.event;
        this.prevRoute = window.location.pathname;
        event.preventDefault();
        window.history.pushState({}, "", event.target.href);
        this.handleLocation();

    };

    handleLocation = async () => {
        if (this.isRouting) return;
        this.isRouting = true;

        this.path = window.location.pathname;
        const route = this.routes[this.path] || this.routes[404];
        this.html = await fetch(route).then((data) => data.text());
        this.trigger('routing');

        if (this.path === '/') {
            this.canvas.style.opacity = '1';
            document.querySelector('.pageContainer').style.opacity = '0';
            document.querySelector('.pageContainer').style.pointerEvents = 'none';
            document.querySelector('.pageContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
            document.querySelector('.aboutContainer').style.opacity = '0';
            document.querySelector('.aboutContainer').style.pointerEvents = 'none';
            document.querySelector('.aboutContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
            document.querySelector('.contactContainer').style.opacity = '0';
            document.querySelector('.contactContainer').style.pointerEvents = 'none';
            document.querySelector('.contactContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;

            this.camera.goOut();
            const pageContent = document.querySelector('.pageContent')
            pageContent ? pageContent.style.transform = 'scale(0)' : null
            setTimeout(() => {
                this.experience.pageOpen = false;
                pageContent ? pageContent.style.scale = 1 : null


            }, this.camera.animationDuration - 200);
        } else if (this.path === '/about') {

            this.experience.nav.appear();
            this.experience.pageOpen = true
            this.camera.goOut();
            const pageContent = document.querySelector('.pageContent')
            pageContent ? pageContent.style.transform = 'scale(0)' : null
            setTimeout(() => {
                this.experience.pageOpen = true;
                pageContent ? pageContent.style.scale = 1 : null


            }, this.camera.animationDuration - 200);

            document.querySelector('.pageContainer').style.opacity = '0';
            document.querySelector('.pageContainer').style.pointerEvents = 'none';
            document.querySelector('.pageContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
            document.querySelector('.contactContainer').style.opacity = '1';

            document.querySelector('.contactContainer').style.pointerEvents = 'none';
            document.querySelector('.contactContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
            setTimeout(() => {
                document.querySelector('.contactContainer').style.zIndex = '1';
                document.querySelector('.aboutContainer').style.zIndex = '2';

            }, 1000)
            document.querySelector('.aboutContainer').style.opacity = '1';
            document.querySelector('.aboutContainer').style.pointerEvents = 'all';
            document.querySelector('.aboutContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
        } else if (this.path === '/contact') {
            this.experience.nav.appear();
            this.experience.pageOpen = true
            this.camera.goOut();
            const pageContent = document.querySelector('.pageContent')
            pageContent ? pageContent.style.transform = 'scale(0)' : null
            setTimeout(() => {
                this.experience.pageOpen = true;
                pageContent ? pageContent.style.scale = 1 : null


            }, this.camera.animationDuration - 200);
            document.querySelector('.pageContainer').style.opacity = '0';
            document.querySelector('.pageContainer').style.pointerEvents = 'none';
            document.querySelector('.pageContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
            document.querySelector('.aboutContainer').style.opacity = '1';

            document.querySelector('.aboutContainer').style.pointerEvents = 'none';
            document.querySelector('.aboutContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;

            document.querySelector('.contactContainer').style.opacity = '1';
            setTimeout(() => {
                document.querySelector('.aboutContainer').style.zIndex = '1';
                document.querySelector('.contactContainer').style.zIndex = '2';

            }, 1000)
            document.querySelector('.contactContainer').style.pointerEvents = 'all';
            document.querySelector('.contactContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
        } else {
            this.experience.nav.appear();
            this.experience.pageOpen = true

            document.querySelector('.pageContainer').style.opacity = '1';
            document.querySelector('.pageContainer').style.pointerEvents = 'all';
            document.querySelector('.pageContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;

            document.querySelector('.aboutContainer').style.opacity = '0';
            document.querySelector('.aboutContainer').style.pointerEvents = 'none';
            document.querySelector('.aboutContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
            document.querySelector('.contactContainer').style.opacity = '0';
            document.querySelector('.contactContainer').style.pointerEvents = 'none';
            document.querySelector('.contactContainer').style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`;
        }


        if (this.path === '/' && this.prevRoute !== '/' && !this.experience.world.isPlaying) {
            const missor = document.querySelector('.logo h2');
            missor.style.transform = 'translateY(-100%)';
            missor.style.pointerEvents = 'none';
        }




        this.isRouting = false;
    };
}
