export default [

    {
        name: 'napoleon',
        type: 'texture',
        path: './images/products/napoleon/1.jpg'

    },
    {
        name: 'dostoievski',
        type: 'texture',
        path: './images/products/dostoievski/1.jpg'

    },
    {
        name: 'jeanne',
        type: 'texture',
        path: './images/products/jeanneDarc/1.jpg'

    },
    {
        name: 'marcAurele',
        type: 'texture',
        path: './images/products/marcAurele/1.jpg'

    },
    {
        name: 'marcAurele2',
        type: 'texture',
        path: './images/products/marcAurele/2.jpg'

    },
    {
        name: 'napoleon2',
        type: 'texture',
        path: './images/products/napoleon/2.jpg'

    },    
    {
        name: 'clickSound',
        type: 'audio',
        path: './sounds/click.mp3'

    },
    {
        name: 'windSound',
        type: 'audio',
        path: './sounds/wind.mp3'

    },
    {
        name: 'ambientSound',
        type: 'audio',
        path: './sounds/ambient.mp3'

    },



]

// {
//     name: '??? ex: modelDeRenard',
//     type: 'gltfModel OU texture OU cubeTexture OU en creer un autre',
//     path: './models/Fox/glTF/Fox.gltf'

// },