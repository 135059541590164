import * as THREE from 'three'
import Experience from "../Experience";
import EventEmitter from '../Utils/EventEmitter';
import AboutCards from './AboutCards';
import AboutCardsLeft from './AboutCardsLeft';
import AboutCardsRight from './AboutCardsRight';






export default class About {

    constructor() {
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.parent = document.querySelector('body')
        this.instance = document.querySelector('.aboutContainer')
        this.router = this.experience.router
        this.scroll = 0
        this.scrollTarget = 0
        this.currentScroll = 0
        this.delta = 0
        this.visible = false
        this.textsArray = [
            'Notre atelier est né à Nice il y a trois ans.',
            'Missor commençait la sculpture à trente ans, et avec ses amis, il ouvrait un atelier pour les produire en nombre.',
            "Petit à petit, l'atelier se mettait à rêver. Et si nous construisions des statues monumentales pour les villes . Et si nous reprenions ce rêve de civilisation, qui avait été abandonné ?",
            "Il est vrai que la Nature engloutit les hommes. Ils meurent tous, et ils sont oubliés.",
            "Mais il est vrai aussi que l'Homme va a l'encontre de son destin. À la manière des jardins de Le Nôtre, qui montrent la virtuosité de l'Homme à dompter les cycles éternels. ",
            "L'acte ultime de l'Homme est de ne pas oublier. Il ne devient cet être singulier que s'il se souvient. S'il se souvient de ce rêve ancien, ce rêve de civilisation.",
            "Faire le Paris la capitale de l'univers"
        ]
        this.cardsArray = []
        this.cardsLeftArray = []
        this.cardsRightArray = []




        this.router.on('routing', () => {
            this.destroyScrollEvent()





            if (window.location.pathname === '/about') {
                this.offset = 0

                this.scrollEvent()
                this.touchEvent()
                setTimeout(() => {
                    this.appear()
                    this.centercol.style.opacity = '1'
                    this.leftcol.style.opacity = '1'
                    this.rightcol.style.opacity = '1'
                    this.centercol.style.scale = '1'
                    this.leftcol.style.scale = '1'
                    this.rightcol.style.scale = '1'
                }, 1000)
                this.scroll = 0
                this.scrollTarget = 0
                this.currentScroll = 0
                this.cardsArray = []
                this.cardsLeftArray = []
                this.cardsRightArray = []
                this.instance.innerHTML = this.router.html
                this.centercol = document.querySelector('.aboutCenterCol')
                this.leftcol = document.querySelector('.aboutLeftCol')
                this.rightcol = document.querySelector('.aboutRightCol')


                this.textsArray.forEach((p, i) => {
                    this.cardsArray.push(
                        new AboutCards(
                            this.centercol,
                            ` ./images/about/${i}.png `,
                            p
                        )

                    )
                    this.cardsLeftArray.push(
                        new AboutCardsLeft(
                            this.leftcol,
                            ` ./images/about/${this.textsArray.length - i - 1}.png `
                        )
                    )
                    this.cardsRightArray.push(
                        new AboutCardsRight(
                            this.rightcol,
                            ` ./images/about/${this.textsArray.length - i - 1}.png `
                        )
                    )


                })

            }

            if(window.location.pathname !== '/about'){
                this.disappear()

            }


        })

    }

    appear() {
        if (Math.round(this.offset) < 350) {
            this.offset += 0.06 * (350 - this.offset)


            requestAnimationFrame(this.appear.bind(this))

        } else {
            this.visible = true
            return
        }
    }
    disappear(){

        if(this.centercol && this.visible){
            this.centercol.style.opacity = '0'
            this.centercol.style.top = '40%'
            this.centercol.style.scale = '0.8'
            
            this.leftcol.style.opacity = '0'
            this.leftcol.style.top = '60%'
            this.leftcol.style.scale = '0.8'

            this.rightcol.style.opacity = '0'
            this.rightcol.style.top =  '60%'
            this.rightcol.style.scale = '0.8'

        } 


      
    }



    scrollEvent() {

        this.scrollCallback = (event) => {
            if (this.experience.pageOpen) {
                this.delta = event.deltaY * 0.001;
                this.scrollTarget += this.delta

            }

        };
        this.initScrollEvent()

    }
    touchEvent() {
        let initialY = null;
        let lastY = null; // Stocke la position Y précédente

        this.touchStartCallback = (event) => {
            initialY = event.touches[0].screenY;
            lastY = initialY; // Initialise lastY avec la position Y initiale
        };

        this.touchMoveCallback = (event) => {
            if (initialY === null) return; // Sortie si la position initiale n'est pas encore définie

            const currentY = event.touches[0].screenY;
            const deltaY = currentY - lastY; // Calcul du déplacement depuis la dernière position Y
            lastY = currentY; // Met à jour la dernière position Y

            // Met à jour la cible de défilement ou effectue d'autres actions en fonction de deltaY
            this.scrollTarget += -deltaY * 0.002;
        };

        this.touchEndCallback = () => {
            initialY = null; // Réinitialise la position initiale lorsque le toucher se termine
        };

        window.addEventListener('touchstart', this.touchStartCallback, { passive: true });
        window.addEventListener('touchmove', this.touchMoveCallback, { passive: true });
        window.addEventListener('touchend', this.touchEndCallback, { passive: true });
    }

    initScrollEvent() {
        window.addEventListener('wheel', this.scrollCallback, { passive: true });

    }


    destroyScrollEvent() {
        window.removeEventListener('wheel', this.scrollCallback);

    }

    updateScrollValues() {

        this.scroll += (this.scrollTarget - this.scroll) * 0.1
        this.scroll *= 0.9
        this.scrollTarget *= 0.9
        this.currentScroll += this.scroll * 0.8

    }

    updateCards() {
        if (this.cardsArray.length && this.currentScroll < 25.1 && this.currentScroll > -0.1) {


            this.cardsArray.forEach((card, i) => {
                card.instance.style.transform = `translateY(${-this.currentScroll * 150 - this.offset + 450 + i * 550}rem)`
                card.update()

            })

        }
        if (this.cardsLeftArray.length && this.currentScroll < 25.1 && this.currentScroll > -0.1) {
            this.cardsLeftArray.forEach((card, i) => {
                card.instance.style.transform = `translateY(${this.currentScroll * 150 + this.offset - 3500 + i * 550}rem)`
                card.update()

            })
        }
        if (this.cardsRightArray.length && this.currentScroll < 25.1 && this.currentScroll > -0.1) {
            this.cardsRightArray.forEach((card, i) => {
                card.instance.style.transform = `translateY(${this.currentScroll * 150 + this.offset - 3500 + i * 550}rem)`
                card.update()

            })
        }


        if (this.currentScroll > 22) {
            this.currentScroll = 22
        } else if (this.currentScroll < 0) {
            this.currentScroll = 0
            this.scrollTarget = 0
        }
    }

    update() {
        this.updateScrollValues()
        this.updateCards()


    }

}