import * as THREE from 'three';
import Experience from '../Experience';

export default class Audio {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.ressources = this.experience.ressources;
        this.time = this.experience.time;
        this.mute = false
        //
        this.listener = new THREE.AudioListener();
        this.camera.instance.add( this.listener );

        // Initialiser les variables pour stocker les différents sons
        this.windSoundBuffer = null;
        this.ambientSoundBuffer = null;
        this.clickSoundBuffer = null;
        this.ambientSound = null
        this.clickSound = null
        this.windSound = null

        this.setSounds()
        document.querySelector('.sound').addEventListener('click', (e)=>{
            this.clickSound.play()

            if(!this.mute){
                document.querySelector('.sound').style.opacity = '0.5'
                this.mute = true
                this.ambientSound.setVolume(0)
                this.windSound.setVolume(0)
                this.clickSound.setVolume(0)
            } else{
                document.querySelector('.sound').style.opacity = '1'

                this.mute = false
                this.ambientSound.setVolume(0.5)
                this.windSound.setVolume(0.1)
                this.clickSound.setVolume(0.5)
                this.ambientSound.play()
                this.clickSound.play()


            }
        })



    }


    setSounds(){
        if(this.ressources.items.ambientSound && this.ressources.items.clickSound && this.ressources.items.windSound){
            this.ambientSoundBuffer =  this.ressources.items.ambientSound
            this.clickSoundBuffer =  this.ressources.items.clickSound
            this.windSoundBuffer =  this.ressources.items.windSound


            this.windSound = new THREE.Audio(this.listener)
            this.clickSound = new THREE.Audio(this.listener)
            this.ambientSound = new THREE.Audio(this.listener)
            this.windSound.setBuffer(this.windSoundBuffer);
            this.clickSound.setBuffer(this.clickSoundBuffer);
            this.ambientSound.setBuffer(this.ambientSoundBuffer);
      
            this.ambientSound.setLoop( true );
            this.ambientSound.setVolume(0.5)
            this.windSound.setVolume(0.1)
            this.clickSound.setVolume(0.5)
    

        
        } else{
            setTimeout(()=>{
                this.setSounds()
            }, 500)
        }
    }


}
