import * as THREE from 'three'
import Experience from "../Experience";
import EventEmitter from '../Utils/EventEmitter';






export default class AboutCards {

    constructor(target, imageURL, textContent) {
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.parent = document.querySelector('body')
        this.instance = document.querySelector('.aboutContainer')
        this.router = this.experience.router
        this.target = target
        this.url = imageURL
        this.textContent = textContent



        this.instance = document.createElement('div')
        this.figure = document.createElement('figure')
        this.img = document.createElement('img')
        this.img.setAttribute('src', this.url)
        this.card = document.createElement('div')
        this.card.classList.add('card')
        this.imageFrame = document.createElement('div')
        this.imageFrame.classList.add('wireframe')
        this.card.appendChild(this.imageFrame)

        this.text = document.createElement('p')
        this.text.classList.add('text')
        this.text.innerHTML = this.textContent
        this.card.appendChild(this.text)

        this.figure.appendChild(this.img)
        this.instance.appendChild(this.figure)
        this.instance.appendChild(this.card)
        this.target.appendChild(this.instance)




    }



    update() {
        const rect = this.instance.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        const distanceFromMiddle = Math.abs(rect.top + rect.height / 2 - viewportHeight / 2);

        const maxOpacity = 1;
        const minOpacity = 0;
        const maxDistance = (viewportHeight / 2) * 2;
        const opacity = 1 - (distanceFromMiddle / maxDistance);
        const scale = 1 - (distanceFromMiddle / maxDistance / 5);


        // Réglage de l'opacité de l'élément
        this.instance.style.opacity = Math.max(minOpacity, Math.min(maxOpacity, opacity));
        this.instance.style.scale = Math.max(minOpacity, Math.min(maxOpacity, scale));

        // Réglage de la hauteur de la figure
        this.figure.style.height = Math.floor(-rect.top + (window.innerHeight / 2) + 200) + 'px';
    }

}