

varying float vElevation;
uniform vec3 u_colorOne;
uniform vec3 u_colorTwo;

void main(){

    float vElevation = clamp(vElevation*30., 0., 1.);
    vec3 newColor = mix(u_colorOne , u_colorTwo , vElevation);

    gl_FragColor = vec4(newColor, 1.);
}