import * as THREE from 'three'
import Sizes from "./Utils/Sizes"
import Time from "./Utils/Time"
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Ressources from './Utils/Ressources.js'
import Debug from './Utils/Debug.js'
import sources from './sources.js'
import PageContainer from './World/PageContainer.js'
import Router from '../router/router.js'
let instance = null
import Nav from './World/Nav.js'
import Panier from './World/Panier.js'
import Basket from './Utils/Basket.js'



export default class Experience {
    constructor(canvas) {
        //on sauvegarde l'experience dans l'instance pour l'envoyers aux autres classes globalement dans le projet as needed

        if (instance) {
            return instance
        }
        instance = this


        // cette ligne permet d'accéder au canvas depuis la console mais il faut en avoir une seul sur la page window.experience devient accessible globalemennt dans le projet (cf: Camera.js constructor)
        window.experience = this

        // Options
        this.canvas = canvas
        this.canvas.style.opacity = 1
        // page container


        //Setup
        this.basket = new Basket()
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.scene.background = new THREE.Color(0xE0E0E0)
        this.ressources = new Ressources(sources)
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.mouse = new THREE.Vector3(0, 0, 3)
        this.mouseEvent()
        this.raycaster = new THREE.Raycaster();
        this.pageOpen = false
        this.router = new Router()
        this.panier = new Panier()
        this.nav = new Nav()
        this.nav.setAnimation()
        this.nav.playAnimation()
        this.world = new World()









        //sizes resize Event
        this.sizes.on('resize', () => {
            this.resize()
        })
        //Time tick Event
        this.time.on('tick', () => {

            this.update()

        })


    }
    mouseEvent() {
        this.mousemoveCallback = (e) => {
            this.mouse.x = (e.clientX / window.innerWidth * 2) - 1
            this.mouse.y = (e.clientY / window.innerHeight * 2) - 1


        }
        window.addEventListener('mousemove', this.mousemoveCallback)
    }

    resize() {

        this.camera.resize()
        this.renderer.resize()

    }
    update() {
        this.raycaster.setFromCamera(this.mouse, this.camera.instance)
        this.camera.update()
        this.raycaster.setFromCamera(this.mouse, this.camera.instance)
        this.world.update()
        this.renderer.update()

    }


    // normalement destroy est come update et appelle la fonction destroy de chaque class pour faire cela proprement

    destroy() {

        //todo : Cette fonction est à complexifier en fonction du projet. par exemple, il faut disposer des effets de post processing (effectComposer)
        this.sizes.off('resize')
        this.time.off('tick')

        // traverse the whole scene
        this.scene.traverse((child) => {

            if (child.isMesh) {
                child.geometry.dispose()


                for (const key in child.material) {
                    const value = child.material[key]

                    if (value && typeof value.dispose === 'function') {
                        value.dispose()
                    }

                }



            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if (this.debug.active) {
            this.debug.ui.destroy()

        }
    }




}