import Experience from "../Experience"
export default class Popup {
    constructor(name, url) {
        this.experience = new Experience()
        this.name = name
        this.url = url
        this.body = document.querySelector('body')
        this.popUp = document.createElement('div')
        this.popUp.addEventListener('click', this.handleClickEvent.bind(this))

        this.push()


        setTimeout(() => {
            this.destroy()

        }, 2500)


    }

    push() {

        const text = document.createElement('p')
        text.innerHTML = this.name
        const image = document.createElement('img')
        image.setAttribute('src', this.url)
        this.popUp.classList.add('popUp')
        this.popUp.appendChild(text)
        this.popUp.appendChild(image)
        this.body.appendChild(this.popUp)
        setTimeout(() => {
            this.popUp.style.transform = 'translateX(10rem)'
            this.popUp.style.opacity = '1'
        }, 1)



    }

    handleClickEvent() {

        this.experience.panier.openPanier()


    }

    destroy() {
        this.popUp.style.opacity = '0'
        this.popUp.style.transform = 'translateX(100rem)'
        this.popUp.removeEventListener('click', this.handleClickEvent.bind(this))

        setTimeout(() => {
            this.body.removeChild(this.popUp)
        }, 500)



    }




}