

import Experience from "../Experience";
import Router from "../../router/router";

export default class Nav {
    constructor() {


        this.experience = new Experience()
        this.router = this.experience.router
        this.camera = this.experience.camera

        this.homeLink = document.querySelector('.nav .logo')
        this.storyLink = document.querySelectorAll('.navLink')

        this.navLink = document.querySelectorAll('.nav .navList .navItem a')
        this.navLinkP = document.querySelectorAll('.nav .navList .navItem a p')
        this.animationContainer = document.querySelector('.soundAnimation')
        this.burgerOpen = false
        this.timeout1 = null
        this.timeout2 = null



        this.navLink.forEach((link) => {
            link.addEventListener('mouseenter', e => {
                link.querySelectorAll('p').forEach((p) => {
                    p.style.transform = 'translateY(-100%)'
                })
            })
        })
        this.navLink.forEach((link) => {
            link.addEventListener('mouseleave', e => {
                link.querySelectorAll('p').forEach((p) => {
                    p.style.transform = 'translateY(0%)'
                })
            })
        })


        if (this.router) {

            this.homeLink.addEventListener('click', (e) => {
                this.experience.world.audio.clickSound.play()

                this.camera.goOut()
                this.experience.router.route()
                setTimeout(() => {
                    this.experience.pageOpen = false

                }, this.camera.animationDuration - 200)



            })

            this.storyLink.forEach((link) => {
                link.addEventListener('click', e => {
                    this.experience.world.audio.clickSound.play()

                    this.experience.router.route()
                    setTimeout(() => {
                        this.experience.pageOpen = true

                    }, this.camera.animationDuration - 200)

                })
            })
            const burgerLinks = document.querySelectorAll('.burgerMenu .navItem')

            burgerLinks.forEach((link) => {
                link.addEventListener('click', e => {
                    this.experience.router.route();
                    this.experience.world.audio.clickSound.play()

                    setTimeout(() => {
                        this.experience.pageOpen = true;
                    }, this.camera.animationDuration - 200);
                });
            });

            this.router.on('routing', () => {

                if (this.burgerOpen) {
                    this.closeBurger()
                    this.burgerOpen = false


                }

                if (window.location.pathname === '/contact' || window.location.pathname === '/about') {
                    setTimeout(() => {
                        document.querySelector('.burgerMenu').style.backgroundColor = '#E0E0E0'
                        document.querySelectorAll('.burgerMenu .navItem a').forEach((lien) => {
                            lien.style.color = 'black'
                        })


                    }, 1000)
                } else {
                    setTimeout(() => {
                        document.querySelector('.burgerMenu').style.backgroundColor = 'black'
                        document.querySelectorAll('.burgerMenu .navItem a').forEach((lien) => {
                            lien.style.color = '#E0E0E0'
                        })

                    }, 1000)

                }
            })
        }

        const burger = document.querySelector('.nav .burger')

        burger.addEventListener('click', this.burgerClickCallback.bind(this))

    }
    burgerClickCallback() {
        this.experience.world.audio.clickSound.play()

        if (!this.burgerOpen) {
            this.burgerOpen = true
            this.openBurger()

        } else {
            this.burgerOpen = false
            this.closeBurger()


        }
    }

    appear() {
        const atelier = document.querySelector('.logo h1')
        const missor = document.querySelector('.logo h2')
        const sound = document.querySelector('.nav .sound p')
        const navP = document.querySelectorAll('.nav .navList .navItem a p')
        const panier = document.querySelector('.panier')
        const soundVisualizer = document.querySelector('.soundAnimation')
        const burger = document.querySelector('.nav .burger')



        atelier.style.transform = 'translateY(0%)'
        missor.style.transform = 'translateY(0%)'
        sound.style.transform = 'translateY(0%)'
        soundVisualizer.style.transform = 'translateY(0%)'
        panier.style.opacity = '1'

        if (burger) {
            burger.style.opacity = '1'

        }



        navP.forEach((p, i) => {
            p.style.transform = 'translateY(0%)'

        })

    }


    openBurger() {

        clearTimeout(this.timeout4)
        clearTimeout(this.timeout5)

        // Efface tous les timeouts en cours de closeBurger() s'ils existent
        clearTimeout(this.closeTimeout);
        document.querySelector('.burgerMenu').style.transition = `none`;
        document.querySelector('.burgerMenu').style.transform = `translateY(100%)`;
        this.timeout1 = setTimeout(() => {

            document.querySelector('.burgerMenu').style.transform = `translateY(0%)`;
            document.querySelector('.burgerMenu').style.transition = `transform 1s ease-in-out`;
        }, 10)

        document.querySelectorAll('.burger .stroke').forEach((s, i) => {
            s.style.marginBottom = '-1rem';

            if (i === 1) {
                s.style.transform = 'rotate(45deg)';
            } else {
                s.style.transform = 'rotate(-45deg)';
            }
        });

        const items = document.querySelectorAll('.burgerMenu .navItem a p');

        items.forEach((item, i) => {
            // Efface tous les timeouts en cours de closeBurger() s'ils existent

            this.timeout2 = setTimeout(() => {
                item.style.transform = 'translateY(0%)';
            }, 800 + i * 100);
        });


    }

    closeBurger() {
        clearTimeout(this.timeout1)
        clearTimeout(this.timeout2)
        // Efface tous les timeouts en cours de openBurger() s'ils existent
        document.querySelector('.burgerMenu').style.transition = `none`;
        document.querySelector('.burgerMenu').style.transform = `translateY(0%)`;
        this.timeout3 = setTimeout(() => {

            document.querySelector('.burgerMenu').style.transform = `translateY(-100%)`;
            document.querySelector('.burgerMenu').style.transition = `transform 1s ease-in-out`;
        }, 10)

        document.querySelectorAll('.burger .stroke').forEach((s, i) => {
            s.style.marginBottom = '10rem';



            if (i === 1) {
                s.style.transform = 'rotate(0)';
            } else {
                s.style.transform = 'rotate(-0)';
            }
        });

        this.timeout4 = setTimeout(() => {
            document.querySelector('.burgerMenu').style.transition = `none`;
            document.querySelector('.burgerMenu').style.transform = `translateY(100%)`;

            this.timeout5 = setTimeout(() => {
                document.querySelector('.burgerMenu').style.transition = `transform 1s ease-in-out`;
            }, 10);
        }, 1010);

        const items = document.querySelectorAll('.burgerMenu .navItem a p');
        items.forEach((item, i) => {

            setTimeout(() => {
                item.style.transform = 'translateY(100%)';
            }, i * 100);
        });



    }


    disappear() {
        const atelier = document.querySelector('.logo h1')
        const missor = document.querySelector('.logo h2')
        const sound = document.querySelector('.nav .sound p')
        const navP = document.querySelectorAll('.nav .navList .navItem a p')
        const panier = document.querySelector('.panier')
        const burger = document.querySelector('.nav .burger')
        if (burger) {
            burger.style.opacity = '0'
        }


        panier.style.opacity = '0'
        atelier.style.transform = 'translateY(-100%)'
        missor.style.transform = 'translateY(100%)'
        sound.style.transform = 'translateY(100%)'
        navP.forEach((p) => {
            p.style.transform = 'translateY(100%)'

        })

    }

    setAnimation() {
        if (this.animationContainer) {
            for (let i = 0; i < 20; i++) {
                this.animationContainer.innerHTML += ` <div class="stroke"></div> `
            }
        }

    }

    playAnimation() {
        if (this.animationContainer) {
            const strokeArray = document.querySelectorAll('.soundAnimation .stroke')
            strokeArray.forEach((stroke, i) => {
                stroke.style.transform = `scaleY(${Math.sin(this.experience.time.elapsed * 0.001 + i * 0.5)})`
            })
            requestAnimationFrame(this.playAnimation.bind(this))
        }
    }
}