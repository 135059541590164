import * as THREE from 'three'
import Experience from "../Experience";
import EventEmitter from '../Utils/EventEmitter';
import Popup from '../Utils/Popup';






export default class PageContainer {

    constructor(allData) {
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.parent = document.querySelector('body')
        this.instance = document.querySelector('.pageContainer')
        this.router = this.experience.router
        this.allData = allData
        this.data = null
        this.pageData = null
        this.productInfos = null
        this.scroll = 0
        this.scrollTarget = 0
        this.currentScroll = 0
        this.imagesArray = []
        this.imageQuantity = 10
        this.touchEvent()
        this.imagesvisible = false
        this.domGenerated = false
        this.imageClicked = false
        this.hovered = false




        this.router.on('routing', () => {
            this.delta = -0.1
            this.scroll = 0
            this.scrollTarget = 0
            this.currentScroll = 0
            this.imagesArray = []
            this.imagesvisible = false
            this.hovered = false



            // add html content to the page, fetch and complete html content
            if (this.allData) {
                this.allData.forEach((data) => {
                    if (window.location.pathname === '/' + data.textureName) {
                        this.data = data
                        this.pageData = this.data.pageData
                    }
                })
            }

            if (this.data && (window.location.pathname !== '/' && window.location.pathname !== '/about' && window.location.pathname !== '/contact')) {
                this.scrollEvent()

                this.instance.innerHTML = this.router.html
                this.productInfos = document.querySelector('.pageProductInfos')

                const title = document.querySelector('.productTitle')




                this.cursor = document.querySelector('.curseur')



                if (title && this.data.name) {
                    title.innerHTML = `<span><span>${this.data.name}</span></span>`



                    this.pageData.forEach((item) => {
                        this.productInfos.innerHTML += `
                        <span>
                            <p>${item}</p>
                        </span>

                        `
                    })

                    // apprition de la page

                    setTimeout(() => {
                        this.strokes = document.querySelectorAll('.pageStroke')
                        this.infos = document.querySelectorAll('.pageProductInfos span p')
                        this.countSection = document.querySelector('.countSection')
                        this.buttonSpan = document.querySelectorAll('.addToCartSection button span span')
                        this.askForScroll = document.querySelectorAll('.askForScroll span p')
                        this.imageSection = document.querySelector(' .imagesSection')
                        this.images = this.data.pageImages


                        this.setAddToCart()





                        this.imageSection.innerHTML = ``

                        for (let i = 0; i < this.imageQuantity; i++) {
                            this.imagesArray.push(this.images[i % this.images.length])
                        }
                        this.imagesArray.forEach((image) => {
                            this.imageSection.innerHTML += `
                            <figure>
                                                            <img src="${image}" alt="" />
                            </figure>

                                                            `
                        })
                        this.domGenerated = true
                        this.figures = this.imageSection.querySelectorAll('figure');
                        this.clickEvent()

                        this.productInfosAppear()

                    }, 500)





                }
            } else if (window.location.pathname !== '/' && window.location.pathname !== '/about' && window.location.pathname !== '/contact') {
                this.instance.innerHTML = ''
                this.destroyScrollEvent()


            }
        })

    }
    scrollEvent() {
        this.destroyScrollEvent()
        let visibleTimeoutActive = false;
        let invisibleTimeoutActive = false;

        this.scrollCallback = (event) => {
            if (this.experience.pageOpen) {
                this.delta = 0;

                this.delta = event.deltaY * 0.001;
                if (this.delta > 0) {
                    this.scrollTarget += this.delta;
                    this.productInfosDisapear();

                    // Annuler le délai d'attente précédent s'il est actif
                    if (invisibleTimeoutActive) {
                        clearTimeout(this.timeOutInvisible);
                        invisibleTimeoutActive = false;
                    }

                    // Définir le délai d'attente pour rendre les images visibles
                    if (!visibleTimeoutActive) {
                        this.timeOutVisible = setTimeout(() => {
                            this.imagesvisible = true;
                            visibleTimeoutActive = false;
                        }, 1000);
                        visibleTimeoutActive = true;
                    }

                } else {
                    // Annuler le délai d'attente précédent s'il est actif
                    if (visibleTimeoutActive) {
                        clearTimeout(this.timeOutVisible);
                        visibleTimeoutActive = false;
                    }

                    // Définir le délai d'attente pour rendre les images invisibles
                    if (!invisibleTimeoutActive) {
                        this.timeOutInvisible = setTimeout(() => {
                            this.imagesvisible = false;
                            invisibleTimeoutActive = false;
                            this.currentScroll = 0;
                            this.delta = 0;
                            this.productInfosAppear();
                        }, 500);
                        invisibleTimeoutActive = true;
                    }
                }
            }
        };

        this.initScrollEvent();
    }

    touchEvent() {
        let initialX = null; // Stocke la position X initiale
        let initialY = null; // Stocke la position X initiale
        let visibleTimeoutActive = false;
        let invisibleTimeoutActive = false;
        this.touchStartCallback = (event) => {
            initialX = event.touches[0].screenX; // Stocke la position X initiale au toucher


        };

        this.touchMoveCallback = (event) => {
            if (!initialX) return; // Sortie si la position initiale n'est pas encore définie

            const currentX = event.touches[0].screenX; // Position X actuelle
            const currentY = -event.touches[0].screenY; // Position X actuelle
            const deltaX = currentX - initialX
            const deltaY = currentY - initialY
            this.delta = deltaX + deltaY; // Calcul du déplacement par rapport à la position initiale


            // Met à jour la position initiale pour le prochain mouvement
            initialX = currentX;
            initialY = currentY;
            if (this.delta > 0) {
                this.scrollTarget += this.delta * 0.001;
                this.productInfosDisapear();

                // Annuler le délai d'attente précédent s'il est actif
                if (invisibleTimeoutActive) {
                    clearTimeout(this.timeOutInvisible);
                    invisibleTimeoutActive = false;
                }

                // Définir le délai d'attente pour rendre les images visibles
                if (!visibleTimeoutActive) {
                    this.timeOutVisible = setTimeout(() => {
                        this.imagesvisible = true;
                        visibleTimeoutActive = false;
                    }, 1000);
                    visibleTimeoutActive = true;
                }

            } else {
                // Annuler le délai d'attente précédent s'il est actif
                if (visibleTimeoutActive) {
                    clearTimeout(this.timeOutVisible);
                    visibleTimeoutActive = false;
                }

                // Définir le délai d'attente pour rendre les images invisibles
                if (!invisibleTimeoutActive) {
                    this.timeOutInvisible = setTimeout(() => {
                        this.imagesvisible = false;
                        invisibleTimeoutActive = false;
                        this.currentScroll = 0;
                        this.delta = 0;
                        this.productInfosAppear();
                    }, 500);
                    invisibleTimeoutActive = true;
                }
            }
        };

        this.touchEndCallback = (event) => {
            initialX = null; // Réinitialise la position initiale lorsque le toucher se termine


        };

        window.addEventListener('touchstart', this.touchStartCallback, { passive: true });
        window.addEventListener('touchmove', this.touchMoveCallback, { passive: true });
        window.addEventListener('touchend', this.touchEndCallback, { passive: true });
    }
    clickEvent() {
        if (this.figures) {
            this.figures.forEach((figure) => {
                figure.addEventListener('mousedown', e => {
                    this.imageClicked = true;
                    figure.querySelector('img').style.transform = 'scale(1.05)';


                });
                figure.addEventListener('mouseup', e => {
                    this.imageClicked = false;
                    figure.querySelector('img').style.transform = 'scale(1)';
                });
                figure.addEventListener('mouseenter', (e) => {
                    this.hovered = true
                })
                figure.addEventListener('mouseleave', (e) => {
                    this.hovered = false
                })
            });
        }

    }

    initScrollEvent() {
        window.addEventListener('wheel', this.scrollCallback, { passive: true });

    }


    destroyScrollEvent() {
        window.removeEventListener('wheel', this.scrollCallback);

    }
    updateScrollValues() {

        this.scroll += (this.scrollTarget - this.scroll) * 0.1
        this.scroll *= 0.9
        this.scrollTarget *= 0.9
        this.currentScroll += this.scroll * 0.8




    }

    openPage() {
        this.instance.style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`
        this.instance.style.opacity = '1'
        this.instance.style.pointerEvents = 'all'

    }

    closePage() {
        this.instance.style.transition = `opacity ${this.camera.animationDuration / 1000}s ease-in-out`
        this.instance.style.opacity = '0'
        this.instance.style.pointerEvents = 'none'

    }

    productInfosDisapear() {

        if (window.location.pathname !== '/about' && window.location.pathname !== '/contact' && window.location.pathname !== '/') {
            const title = document.querySelector('.productTitle')

            title.style.transform = `translateY(100%)`
            this.askForScroll.forEach((item) => {
                item.style.transform = `translateY(100%)`
            })
            this.strokes.forEach((stroke, i) => {

                if (i !== this.strokes.length - 1 && i !== this.strokes.length - 2) {
                    setTimeout(() => {
                        stroke.style.width = '0%'
                    }, i * 100)
                }

            })
            this.infos.forEach((info, i) => {
                setTimeout(() => {
                    info.style.transform = `translateY(100%)`
                }, i * 100)

            })
        }

    }
    productInfosAppear() {
        const title = document.querySelector('.productTitle')

        title.style.transform = `translateY(0%)`
        this.askForScroll.forEach((item) => {
            item.style.transform = `translateY(0%)`
        })
        this.strokes.forEach((stroke, i) => {
            setTimeout(() => {
                stroke.style.width = '100%'
            }, i * 100)
        })
        this.infos.forEach((info, i) => {
            setTimeout(() => {
                info.style.transform = `translateY(0%)`
            }, i * 100)

        })
        this.countSection.style.transform = `translateY(0%)`
        this.buttonSpan.forEach((span) => { span.style.transform = `translateY(0%)` })
    }




    imagesUpdate() {
        if (window.location.pathname !== '/' && window.location.pathname !== '/about' && window.location.pathname !== '/contact') {

            if (this.imagesvisible && this.delta > 0) {
                const figures = this.imageSection.querySelectorAll('figure');


                figures.forEach((figure, i) => {
                    const reverseIndex = figures.length - 1 - i;
                    const margin = 30
                    const modulo = ((figures.length * margin) - 1 * 10)
                    const translateX = Math.abs((reverseIndex * margin + this.currentScroll * 50) % modulo)


                    figure.style.width = '250rem'

                    figure.style.transform = `translateX(${translateX}rem)`
                    figure.style.opacity = Math.abs(1 - (translateX / modulo)) + 0.1


                    window.innerWidth > 1110 ? figure.style.scale = Math.abs(1 - (translateX / modulo)) + 0.75 : figure.style.scale = Math.abs(1 - (translateX / modulo)) + 0.75 - 0.2
                    figure.style.zIndex = Math.floor(1 / (translateX / modulo * figures.length + 3) * 100)
                    if (!this.imageClicked) {
                        this.currentScroll += 0.0005
                    }


                })
            } else if (this.delta < 0 && this.figures) {
                this.figures.forEach((figure) => {
                    figure.style.width = 0
                })


            }

        } else {
            if (this.figures) {
                this.figures.forEach((figure, i) => {
                    figure.style.width = '0rem'
                })
            }


        }




    }
    imagesDisapear() {
        const figures = this.imageSection.querySelectorAll('figure');
        figures.forEach((figure, i) => {
            figure.style.width = '0rem'
        })

    }
    imagesAppear() {
        const figures = this.imageSection.querySelectorAll('figure');
        figures.forEach((figure, i) => {
            setTimeout(() => {
                figure.style.width = '250rem'


            }, i * 100)
        })
    }
    setAddToCart() {


        // TODO

        this.addToCartSection = document.querySelector('.addToCartSection')
        this.prixUnitaire = document.querySelectorAll('.countSection .total p')[1]
        this.prixTotal = document.querySelectorAll('.countSection .total p')[0]
        this.counterValue = 1
        this.counterIncrement = document.querySelector('.counter button.increment')
        this.counterDecrement = document.querySelector('.counter button.decrement')
        this.price = this.data.price

        this.prixUnitaire.innerHTML = `&nbsp;/ Prix Unitaire : ${this.data.price}€`
        this.prixTotal.innerHTML = `TOTAL : ${this.data.price * this.counterValue}€`
        this.addToCartButton = document.querySelector('.pageContent .ajoutAuPanier')



        // addToCart section

        this.counterIncrement.addEventListener('click', e => {
            this.experience.world.audio.clickSound.play()

            this.counterValue = this.counterValue + 1
            document.querySelector('.counter p').innerHTML = this.counterValue
            this.prixTotal.innerHTML = `TOTAL : ${this.data.price * this.counterValue}€`

        })




        this.counterDecrement.addEventListener('click', e => {
            this.experience.world.audio.clickSound.play()

            if (this.counterValue > 1) {
                this.counterValue -= 1
                document.querySelector('.counter p').innerHTML = this.counterValue
                this.prixTotal.innerHTML = `TOTAL : ${this.data.price * this.counterValue}`

            }

        })
        this.addToCartButton.addEventListener('click', e => {

            this.experience.world.audio.clickSound.play()

            new Popup(this.data.name, this.data.pageImages[0])

            document.querySelector('.panier-size').style.scale = '0'
            setTimeout(() => {
                document.querySelector('.panier-size').style.scale = '1'
                for (let i = 0; i < this.counterValue; i++) {
                    this.experience.basket.add(this.data)
                }
                this.experience.panier.update()

            }, 600)







        })



    }

    cursorUpdate() {
        if (this.cursor) {
            this.cursor.style.transform = `translate(${this.experience.mouse.x * window.innerWidth / 2 - 50}rem, ${this.experience.mouse.y * window.innerHeight / 2 - 50}rem)`

        }

        if (this.hovered) {
            this.cursor.style.transform = `translate(${this.experience.mouse.x * window.innerWidth / 2 - 50}rem, ${this.experience.mouse.y * window.innerHeight / 2 - 50}rem) scale(1.2)`
            this.cursor.style.opacity = `1`

            document.querySelector('.curseur span p').style.transform = `translateY(0%)`


            if (this.imageClicked) {
                this.cursor.style.transform = `translate(${this.experience.mouse.x * window.innerWidth / 2 - 50}rem, ${this.experience.mouse.y * window.innerHeight / 2 - 50}rem) scale(2)`
                document.querySelector('.curseur span p').style.transform = `translateY(-200%)`
                this.cursor.style.opacity = `0.2`
                document.querySelector('.curseur span p').style.scale = `0.5`
                this.cursor.style.backdropFilter = `blur(10px);`




            } else {
                document.querySelector('.curseur span p').style.scale = `1`

            }

        } else {
            document.querySelector('.curseur span p') ? document.querySelector('.curseur span p').style.transform = `translateY(100%)` : null
            this.cursor.style.opacity = `0`


        }




    }

    update() {
        this.updateScrollValues()

        this.imagesUpdate()
        if (this.cursor) {
            this.cursorUpdate()

        }

    }

}