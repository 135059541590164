import * as THREE from 'three'
import Experience from "../../Experience";
import brush from '../../../../static/images/brush.png'





export default class Ripples {

    constructor(target, width, height, size, offsetX, offsetY) {


        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.ressources = this.experience.ressources
        this.time = this.experience.time
        this.target = target
        this.size = size
        this.width = width
        this.height = height
        this.offsetX = offsetX
        this.offsetY = offsetY
        this.currentWave = 0
        this.mouse = this.experience.mouse
        this.prevUV = new THREE.Vector3(0, 0, 0);
        this.UV = new THREE.Vector3(0, 0, 0)
        this.projectedUV = new THREE.Vector3(0, 0, 0)
        this.currentWave = 0
        this.raycaster = this.experience.raycaster
        this.newScene = new THREE.Scene()
        this.newCamera = new THREE.PerspectiveCamera(
            35,
            this.width / this.height,
            0.1,
            100
        )

        this.newCamera.position.set(0, 0, 3)
        // this.newCamera.lookAt(new THREE.Vector3(0, 0, 0))


        this.setGeometry()
        this.setTextures()
        // this.setMaterial()
        this.setMesh()


        this.baseTexture = new THREE.WebGLRenderTarget(
            this.width,
            this.height,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,



            })






    }
    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(this.size, this.size)
    }
    setTextures() {
        this.brushTexture = this.ressources.items.brushTexture
    }


    setMesh() {
        this.max = 100
        this.meshes = []

        for (let i = 0; i < this.max; i++) {
            const material = new THREE.MeshBasicMaterial({
                map: new THREE.TextureLoader().load(brush),
                transparent: true,
                blending: THREE.AdditiveBlending,
                depthTest: false,
                depthWrite: false,
            })
            this.mesh = new THREE.Mesh(this.geometry, material)
            this.mesh.visible = false
            this.mesh.rotation.z = 2 * Math.PI * Math.random()
            this.meshes.push(this.mesh)
            this.newScene.add(this.mesh)

        }
    }





    setNewWave(index) {

        const mesh = this.meshes[index]
        mesh.visible = true
        mesh.material.opacity = 1
        mesh.scale.x = 0.5
        mesh.scale.y = 0.5
        mesh.position.x = this.projectedUV.x
        mesh.position.y = -this.projectedUV.y
        // mesh.position.x = 0
        // mesh.position.y = 0

    }


    trackMousePos() {



        // raycaster
        const intersects = this.raycaster.intersectObject(this.target)
        if (intersects.length) {
            const x = intersects[0].uv.x * 2 - 1;
            const y = intersects[0].uv.y * 2 - 1;
            this.UV.x = x
            this.UV.y = y - 0.2


            const thresholdX = Math.abs((this.UV.x - this.prevUV.x)) * 100
            const thresholdY = Math.abs((this.UV.y - this.prevUV.y)) * 100
            this.currentWave = (this.currentWave + 1) % this.max

            if (thresholdX > 1.5 || thresholdY > 1.5) {
                this.setNewWave(this.currentWave)

            } else {
                // nothing
            }



        }

    }
    destroy() {
        this.geometry.dispose()
        this.meshes.forEach((mesh) => {
            mesh.material.dispose()
            this.newScene.remove(mesh)
        })
        this.baseTexture.dispose()
        this.experience.renderer.instance.setRenderTarget(null);
        this.experience.renderer.instance.clear();
        this.newScene.remove(this.newCamera)

    }


    update() {



        // renderTarget
        this.experience.renderer.instance.setRenderTarget(this.baseTexture)
        this.experience.renderer.instance.render(this.newScene, this.newCamera)
        this.experience.renderer.instance.setRenderTarget(null)
        // this.experience.renderer.instance.clear()
        // this.experience.renderer.instance.render(this.scene, this.camera.instance)

        this.target.material.uniforms.u_displacement.value = this.baseTexture.texture

        this.projectedUV = this.UV.unproject(this.camera.instance)
        this.trackMousePos()
        this.prevUV.copy(this.UV);


        this.meshes.forEach((mesh, i) => {

            if (mesh.visible) {
                mesh.rotation.z += this.time.delta;
                mesh.material.opacity *= 0.965
                mesh.scale.x = 0.98 * mesh.scale.x + 0.08
                mesh.scale.y = 0.98 * mesh.scale.y + 0.08

                if (mesh.opacity < 0.002) {
                    mesh.visible = false
                }
            }


        });






    }


}