import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'



export default class Camera {


    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.slideIn = true
        this.animationDuration = 1000

        this.setInstance()
        // this.setOrbitControls()

    }

    setInstance() {
        //une instance de la camera
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width /
            this.sizes.height,
            0.1,
            100
        )


        this.instance.position.set(0, 0.3, 3)

        this.scene.add(this.instance)


    }
    goIn() {
        const targetPosition = new THREE.Vector3(0, 0.3, 0.2);
        const targetQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, 0));
        this.animatePosition(targetPosition, targetQuaternion);
    }

    goOut() {
        const targetPosition = new THREE.Vector3(0, 0.3, 3);
        const targetQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, 0));
        this.animatePosition(targetPosition, targetQuaternion);
    }

    animatePosition(targetPosition, targetQuaternion) {
        const startPosition = this.instance.position.clone();
        const startQuaternion = this.instance.quaternion.clone();
        const startTime = Date.now();

        function easeInOut(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        }

        function updatePosition() {
            const currentTime = Date.now();
            const elapsedTime = Math.min(this.animationDuration, currentTime - startTime);
            const t = easeInOut(elapsedTime / this.animationDuration);

            const newPosition = new THREE.Vector3().lerpVectors(startPosition, targetPosition, t);
            const newQuaternion = new THREE.Quaternion().slerpQuaternions(startQuaternion, targetQuaternion, t);
            this.instance.position.copy(newPosition);
            this.instance.quaternion.copy(newQuaternion);

            if (elapsedTime < this.animationDuration) {
                requestAnimationFrame(updatePosition.bind(this));
            }
        }

        updatePosition.call(this);
    }


    toggleSlide() {
        if (this.slideIn) {
            this.goIn()
            this.slideIn = false
        } else {
            this.goOut()
            this.slideIn = true

        }
    }
    setOrbitControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }
    resize() {


        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }
    update() {
        // this.controls.update()

    }




}