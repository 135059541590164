import * as THREE from 'three'
import Experience from "../Experience";
import vertexShader from '../../shaders/product/vertex.glsl'
import fragmentShader from '../../shaders/product/fragment.glsl'
import Ripples from './loadingScreen/Ripples';
import Card from './card/Card';







export default class Product {

    constructor(x, texture, data) {


        this.experience = new Experience()
        this.scene = this.experience.scene
        this.ressources = this.experience.ressources
        this.time = this.experience.time
        this.debugObject = {}
        this.x = x
        this.texture = texture
        this.visible = false
        this.translateAppear = false
        this.data = data




        this.setGeometry()
        this.setMaterial()
        this.setMesh()
        this.appear()
        this.ripples = new Ripples(this.mesh, 1000, 1500, 0.2)
        this.card = new Card(this)





    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(1, 1.5)
    }


    setMaterial() {

        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            wireframe: false,
            transparent: true,
            uniforms: {
                u_time: { value: this.experience.time.elapsed },
                u_alpha: { value: 0 },
                u_texture: { value: this.texture },
                u_displacement: { value: null },
            }
        })

        if (this.experience.debug.active) {

            // debug ui for the material


        }


    }
    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.set(this.x + 1, 0.15, -0.7)
        this.scene.add(this.mesh)


    }



    destroy() {

    }

    appear() {
        if (this.material.uniforms.u_alpha.value < 1 - Math.abs(this.mesh.position.x * 0.15)) {
            this.material.uniforms.u_alpha.value += 0.001
            requestAnimationFrame(this.appear.bind(this))
            return false
        } else {

            return true
        }
    }


    update() {
        this.material.uniforms.u_time.value = this.experience.time.elapsed * 0.001

        if (this.ripples && window.location.pathname === '/') {
            this.ripples.update();
        }

        if (this.appear()) {
            this.material.uniforms.u_alpha.value = 1 - Math.abs(this.mesh.position.x * 0.15)

        }


        if (this.card) {
            this.card.update()

        }



    }


}