import * as THREE from 'three'
import Experience from "../../Experience";
import route from '../../../router/router.js'
import Panier from '../Panier.js';
import Popup from '../../Utils/Popup.js';







export default class Card {

    constructor(target) {


        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.time = this.experience.time
        this.debugObject = {}
        this.parent = document.querySelector('body')
        this.target = target
        this.position = this.target.mesh.position.clone()
        this.position.project(this.camera.instance)
        this.pageContainer = this.experience.world.pageContainer
        this.productData = this.experience.world.productsData



        this.setCard()
        this.discoverBtn.addEventListener('click', () => {
            this.openProductPage()
            this.experience.router.route()
            this.experience.world.audio.clickSound.play()
            this.experience.world.audio.windSound.play()
        })
    }

    setCard() {
        // discover button
        this.card = document.createElement('div')
        this.card.classList.add('card')
        this.discoverBtn = document.createElement('a')
        this.discoverBtn.setAttribute('href', `/${this.target.data.textureName}`)
        this.discoverBtnfirstSpan = document.createElement('span')
        this.discoverBtnSecondSpan = document.createElement('span')
        this.discoverBtn.appendChild(this.discoverBtnfirstSpan)
        this.discoverBtnfirstSpan.appendChild(this.discoverBtnSecondSpan)
        this.discoverBtnSecondSpan.innerHTML = 'découvrir </br> découvrir'
        this.discoverBtn.classList.add('discoverBtn')


        // data
        this.data = document.createElement('div')


        // product infos
        this.productInfos = document.createElement('div')
        this.infosLeft = document.createElement('div')
        this.prix = document.createElement('div')
        this.prix.classList.add('prix')
        this.infosLeft.classList.add('infosLeft')
        this.productInfos.appendChild(this.infosLeft)
        this.productInfos.appendChild(this.prix)
        this.productInfos.classList.add('productInfos')
        this.infosLeftSpan1 = document.createElement('span')
        this.name = document.createElement('p')
        this.infosLeftSpan1.appendChild(this.name)
        this.name.classList.add('name')
        // ?affichage du nom du produit
        this.name.innerHTML = this.target.data.name

        this.infosLeftSpan2 = document.createElement('span')
        this.material = document.createElement('p')
        this.infosLeftSpan2.appendChild(this.material)
        this.material.classList.add('material')
        this.infosLeft.appendChild(this.infosLeftSpan1)
        this.infosLeft.appendChild(this.infosLeftSpan2)
        // ?affichage du materiaux
        this.material.innerHTML = 'Buste en Bronze'

        this.spanPrix = document.createElement('span')
        this.prix.appendChild(this.spanPrix)
        this.prixContent = document.createElement('p')
        this.spanPrix.appendChild(this.prixContent)
        // ?affichage du prix
        this.prixContent.innerHTML = this.target.data.price + '€'




        // add to cart
        this.addToCart = document.createElement('div')
        this.addToCart.classList.add('addToCart')
        this.topBorder = document.createElement('div')
        this.topBorder.classList.add('topBorder')

        this.addToCartButton = document.createElement('button')

        this.bottomBorder = document.createElement('div')
        this.bottomBorder.classList.add('bottomBorder')
        this.addToCart.appendChild(this.topBorder)
        this.addToCart.appendChild(this.addToCartButton)
        this.addToCart.appendChild(this.bottomBorder)
        this.cartButtonSpan1 = document.createElement('span')
        this.cartButtonSpan2 = document.createElement('span')
        this.addToCartButton.appendChild(this.cartButtonSpan1)
        this.cartButtonSpan1.appendChild(this.cartButtonSpan2)
        this.cartButtonSpan2.innerHTML = 'Ajouter au panier </br> Ajouter au panier'
        this.data.appendChild(this.productInfos)
        this.data.appendChild(this.addToCart)
        this.data.classList.add('data')
        this.parent.appendChild(this.card)
        this.card.appendChild(this.discoverBtn)
        this.card.appendChild(this.data)

        this.addToCartButton.addEventListener('click', e => {
            // ajouter un element au panier
            document.querySelector('.panier-size').style.scale = '0'
            new Popup(this.target.data.name, this.target.data.pageImages[0])
            this.experience.world.audio.clickSound.play()

            setTimeout(() => {
                document.querySelector('.panier-size').style.scale = '1'
                this.experience.basket.add(this.target.data)
                this.experience.panier.update()

            }, 600)

        })

    }



    openProductPage() {
        this.camera.goIn()
        this.experience.pageOpen = true

    }





    appear() {

        this.discoverBtnSecondSpan.style.transform = `translateY(10%)`
        this.discoverBtn.style.opacity = '1'
        this.prixContent.style.transform = `translateY(0%)`
        this.cartButtonSpan2.style.transform = `translateY(0%)`
        this.material.style.transform = `translateY(0%)`
        this.name.style.transform = `translateY(0%)`
        this.bottomBorder.style.transform = 'scaleX(1)'
        this.topBorder.style.transform = 'scaleX(1)'
        this.discoverBtn.style.pointerEvents = 'all'
        this.addToCartButton.style.pointerEvents = 'all'
        this.cartButtonSpan2.style.opacity = '1'


    }
    disappear() {
        this.discoverBtn.style.opacity = '0'
        this.discoverBtnSecondSpan.style.transform = `translateY(100%)`
        this.prixContent.style.transform = `translateY(100%)`
        this.cartButtonSpan2.style.transform = `translateY(100%)`
        this.material.style.transform = `translateY(100%)`
        this.name.style.transform = `translateY(100%)`
        this.bottomBorder.style.transform = 'scaleX(0)'
        this.topBorder.style.transform = 'scaleX(0)'
        this.discoverBtn.style.pointerEvents = 'none'
        this.addToCartButton.style.pointerEvents = 'none'
        this.cartButtonSpan2.style.opacity = '0'




    }


    update() {
        if (this.target.mesh.position.x < 0.8 && this.target.mesh.position.x > -0.8 && !this.experience.pageOpen) {
            this.appear()
        } else {
            this.disappear()
        }
        this.position = this.target.mesh.position.clone()
        this.discoverBtn.style.scale = this.target.mesh.scale.x
        this.data.style.scale = this.target.mesh.scale.x
        this.position.project(this.camera.instance)

        const translateX = this.position.x * this.experience.sizes.width * 0.5
        const translateY = -this.position.y * this.experience.sizes.height * 0.5 + 2

        this.card.style.transform = `translate(${translateX}px,${translateY}px) `


    }


}