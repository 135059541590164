uniform float u_alpha;
uniform sampler2D u_texture;
uniform sampler2D u_displacement;
float PI = 3.14159265358979323846264338;


varying vec2 vUv;


void main(){

vec2 displacementUV = vec2(vUv.x, vUv.y);
     vec4 displacement = texture2D(u_displacement, displacementUV);
     float theta = displacement.r * 2.*PI;
          vec2 dir = vec2(sin(theta), cos(theta));
     vec2 newUv = vUv + dir * displacement.r*0.02;
   vec4 imageTexture = texture2D(u_texture, newUv);



    gl_FragColor = vec4(imageTexture.xyz, u_alpha);
}