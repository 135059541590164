import * as THREE from 'three'
import Experience from "../Experience";
import EventEmitter from '../Utils/EventEmitter';






export default class Contact {

    constructor() {
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.parent = document.querySelector('body')
        this.instance = document.querySelector('.contactContainer')
        this.router = this.experience.router


        this.router.on('routing', () => {


            let appearTimeoutId;
            let disappearTimeoutId;
            
            if (window.location.pathname === '/contact') {
                this.instance.innerHTML = this.router.html;
                this.strokes = document.querySelectorAll('.contactStroke');
                this.titles = document.querySelectorAll('.contactContainer .title h1')
                this.itemsImg = document.querySelectorAll('.contactItemsContainer .item span a ')
                this.itemsP = document.querySelectorAll('.contactItemsContainer .item span p')
                this.designer = document.querySelector('.contactDesigner span p')
            
                clearTimeout(disappearTimeoutId);
                appearTimeoutId = setTimeout(() => {
                    this.appear();
                }, 1000);
            } else if(this.router.prevRoute === '/contact'){
                clearTimeout(appearTimeoutId);
                this.disapear();

                disappearTimeoutId = setTimeout(() => {
                    setTimeout(() => {
                        this.instance.innerHTML = '';
                    }, 1000);
                }, 1000);
            }
            
          

        })

    }



    appear(){
            this.strokes.forEach((s)=>s.style.width = '100%') 
            this.titles.forEach((t)=>t.style.transform = 'translateY(0%)') 
            this.itemsImg.forEach((img, i)=>{setTimeout(()=>{img.style.transform = 'translateY(0%)'}, i*150)}) 
            this.itemsP.forEach((p, i)=>{setTimeout(()=>{p.style.transform = 'translateY(0%)'}, i*150+150)}) 
            this.designer.style.transform = 'translateY(0%)'

    }
    disapear(){
        this.strokes.forEach((s)=>s.style.width = '0%') 
        this.titles.forEach((t)=>t.style.transform = 'translateY(-100%)') 
        this.itemsImg.forEach((img, i)=>{img.style.transform = 'translateY(110%)'}) 
        this.itemsP.forEach((p)=>{p.style.transform = 'translateY(170%)'}) 
        this.designer.style.transform = 'translateY(-100%)'


       

    }
}