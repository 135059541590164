import * as THREE from 'three'
import Experience from "../Experience";
import Environment from './Environment';
import LoadingScreen from './loadingScreen/LoadingScreen';
import AmbientSphere from './AmbientSphere'
import Floor from './Floor'
import Product from './product';
import PageContainer from './PageContainer'
import About from './About';
import Contact from './Contact';
import Audio from './Audio';

export default class World {

    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.ressources = this.experience.ressources
        this.time = this.experience.time
        this.isPlaying = false
        this.scroll = 0
        this.scrollTarget = 0
        this.currentScroll = 0
        this.apparitionOffset = 0
        this.screenX = 0
        this.audio = new Audio()


        this.loadingScreen = new LoadingScreen()
        this.ambientSphere = new AmbientSphere()
        this.floor = new Floor()
        document.body.style.overflow = 'hidden';


        this.productsData = []
        this.productFetch = fetch('./productData.json')
            .then(response => response.json())
            .then(data => {
                for (let i = 0; i < data.data.length; i++) {
                    this.productsData.push(data.data[i])
                }
            })

        // adds to the world

        this.productsArray = []
        this.pageContainer = new PageContainer(this.productsData)
        this.pageAbout = new About()
        this.pageContact = new Contact()

        this.loadingScreen.on('enterWebSite', () => {
            this.startMusic()

            this.floor.appear()
            this.productsInit()
            this.scrollEvent()
            this.touchEvent()
            this.isPlaying = true
        })

        this.update()

    }
    startMusic() {
        if (this.experience.world.audio.clickSound && this.experience.world.audio.ambientSound && window.innerWidth >= 1110) {
            this.experience.world.audio.clickSound.play()
            this.experience.world.audio.ambientSound.play()
        } else {
            setTimeout(() => {
                this.startMusic()
            }, 100)
        }

    }

    productsInit() {
        if (this.productsData.length) {
            this.productsData.forEach((product, i) => {
                const texture = this.experience.ressources.items[product.textureName]
                setTimeout(() => {
                    let newProduct = new Product((i * 1.5), texture, product)

                    this.productsArray.push(newProduct)



                }, i * 200)
            })
            return
        } else {
            requestAnimationFrame(this.productsInit.bind(this))
        }
    }


    scrollEvent() {

        this.scrollCallback = (event) => {
            if (!this.experience.pageOpen) {
                const delta = event.deltaY * 0.001;
                this.scrollTarget += delta
            }

        };
        this.initScrollEvent()

    }

    initScrollEvent() {
        window.addEventListener('wheel', this.scrollCallback, { passive: true });

    }


    destroyScrollEvent() {
        window.removeEventListener('wheel', this.scrollCallback);

    }

    touchEvent() {
        let initialX = null; // Stocke la position X initiale

        this.touchStartCallback = (event) => {
            initialX = event.touches[0].screenX; // Stocke la position X initiale au toucher
        };

        this.touchMoveCallback = (event) => {
            if (!initialX) return; // Sortie si la position initiale n'est pas encore définie

            const currentX = event.touches[0].screenX; // Position X actuelle
            const deltaX = currentX - initialX; // Calcul du déplacement par rapport à la position initiale

            this.scrollTarget += deltaX * 0.002; // Ajustement de la cible de défilement en fonction du déplacement

            // Met à jour la position initiale pour le prochain mouvement
            initialX = currentX;
        };

        this.touchEndCallback = (event) => {
            initialX = null; // Réinitialise la position initiale lorsque le toucher se termine
        };

        window.addEventListener('touchstart', this.touchStartCallback, { passive: true });
        window.addEventListener('touchmove', this.touchMoveCallback, { passive: true });
        window.addEventListener('touchend', this.touchEndCallback, { passive: true });
    }


    updateScrollValues() {

        this.scroll += (this.scrollTarget - this.scroll) * 0.1
        this.scroll *= 0.9
        this.scrollTarget *= 0.9
        this.currentScroll += this.scroll * 0.8

    }

    updateMeshes() {
        this.margin = 1.5
        this.wholeWidth = this.productsArray.length * (this.margin)

        this.productsArray.forEach((product, i) => {



            product.mesh.position.x = ((i * this.margin) + this.currentScroll + this.apparitionOffset + 999 * this.wholeWidth) % this.wholeWidth - 2 * this.margin + Math.sin(this.time.elapsed * 0.001 + i) * 0.02
            product.mesh.position.y = -Math.pow(product.mesh.position.x * 0.15, 2) + 0.2 + Math.cos(this.time.elapsed * 0.001 + i) * 0.005
            product.mesh.scale.x = -Math.pow(product.mesh.position.x * 0.15, 2) + 1
            product.mesh.scale.y = -Math.pow(product.mesh.position.x * 0.15, 2) + 1






        })

    }




    update() {

        if (window.location.pathname === '/about') {
            this.pageAbout.update()
        }

        if (this.loadingScreen) {
            this.loadingScreen.update()
        }

        this.updateScrollValues()

        this.updateMeshes()
        if (this.floor) {
            this.floor.update()

        }

        if (this.isPlaying && this.productsArray.length) {
            this.productsArray.forEach((product, i) => {
                product.update()




            })



        }

        if (this.isPlaying && this.apparitionOffset.toFixed(5) < 1.5) {
            this.apparitionOffset += 0.05 * (1.5 - this.apparitionOffset)

        }

        if (this.experience.pageOpen) {
            this.pageContainer.update()
        }


    }

}